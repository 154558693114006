var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState, createContext, useContext, } from 'react';
import { initKeycloakClient } from '../keycloak/keycloak';
var AuthContext = createContext(undefined);
export var AuthProvider = function (_a) {
    var children = _a.children, keycloakConfig = _a.keycloakConfig, keycloakInitOptions = _a.keycloakInitOptions;
    var _b = useState(false), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var _c = useState(false), keycloakInitialized = _c[0], setKeycloakInitialized = _c[1];
    var _d = useState([]), permissions = _d[0], setPermissions = _d[1];
    var _e = useState(''), userId = _e[0], setUserId = _e[1];
    var _f = useState(''), username = _f[0], setUsername = _f[1];
    var keycloakClient = useMemo(function () { return initKeycloakClient(keycloakConfig); }, [keycloakConfig]);
    var handleLogin = useCallback(function () { return keycloakClient.login(); }, [keycloakClient]);
    var handleLogout = useCallback(function () { return keycloakClient.logout(); }, [keycloakClient]);
    var handleRegister = useCallback(function () { return keycloakClient.register(); }, [keycloakClient]);
    useEffect(function () {
        keycloakClient.onReady = function (authenticated) {
            var _a, _b, _c, _d;
            setIsAuthenticated(authenticated);
            setKeycloakInitialized(true);
            setPermissions(((_b = (_a = keycloakClient.tokenParsed) === null || _a === void 0 ? void 0 : _a.realm_access) === null || _b === void 0 ? void 0 : _b.roles) || []);
            setUserId(((_c = keycloakClient.tokenParsed) === null || _c === void 0 ? void 0 : _c.sub) || '');
            setUsername(((_d = keycloakClient.tokenParsed) === null || _d === void 0 ? void 0 : _d.name) || '');
        };
        keycloakClient.onAuthLogout = function () {
            setIsAuthenticated(false);
            setPermissions([]);
            setUserId('');
            setUsername('');
            handleLogout();
        };
        keycloakClient.init(__assign(__assign({}, keycloakInitOptions), { redirectUri: window.location.origin, enableLogging: process.env.NODE_ENV === 'development' }));
    }, [keycloakClient, keycloakInitOptions, handleLogout]);
    if (!keycloakInitialized) {
        return null;
    }
    return (_jsx(AuthContext.Provider, __assign({ value: {
            isAuthenticated: isAuthenticated,
            handleLogin: handleLogin,
            handleLogout: handleLogout,
            handleRegister: handleRegister,
            permissions: permissions,
            userId: userId,
            username: username,
        } }, { children: children })));
};
export var useAuth = function () {
    var context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("Context Provider for Auth Store is missing");
    }
    return context;
};
