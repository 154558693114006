import { type Rule } from 'sanity';

export default {
  name: 'Logo',
  title: 'Logo',
  type: 'document',
  fields: [
    {
      name: 'image',
      title: 'Logo',
      type: 'image',
      description: 'The image for the client application logo',
      validation: (Rule: Rule) => Rule.required(),
    },
  ],
};
