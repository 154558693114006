import { Save } from '@mui/icons-material';
import {
  IconButton,
  MenuItem,
  Table as MuiTable,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { StyleObj } from '../../@types';
import { Liability, ProductType, UserProductLiability } from '../../@types/api';
import { PRODUCT_TYPES_OPTIONS, QUERY_KEYS } from '../../constants';
import useMutateData from '../../hooks/useMutateData';
import { usePagination } from '../../hooks/usePagination';
import { getData } from '../../utils/api';

const styles: StyleObj = {
  table: {
    '& .MuiTableRow-head': {
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      backgroundColor: 'primary.main',
      '& th': {
        color: 'white',
      },
      '& th:first-child': {
        borderRadius: '8px 0 0 0',
      },
      '& th:last-child': {
        borderRadius: '0 8px 0 0',
      },
    },
    '& .MuiTableRow-root': {
      '& td': {
        p: 1,
        borderBottom: 'none',
      },
    },
    '& .MuiTableRow-root:nth-of-type(even)': {
      backgroundColor: 'background.light',
    },
  },
  itemNameCell: {
    backgroundColor: 'background.lightGreen',
    border: '1px solid rgba(0, 83, 55, 0.20)',
    borderBottom: '1px solid rgba(0, 83, 55, 0.20) !important',
  },
  select: {
    width: '100%',
    fontSize: 14,
  },
};

const defaultValues = {
  'pre-match': '',
  'in-play': '',
};

const LiabilityAssignTable = () => {
  const [values, setValues] = useState<Record<ProductType, string>>(defaultValues);

  const { data: liabilitiesData } = usePagination<Liability>('risk-management/liabilities', {
    page: 1,
    limit: 100,
  });

  const { data: userProductLiabilities, isSuccess } = useQuery([QUERY_KEYS.userProductLiabilities], {
    queryFn: (): Promise<{ items: UserProductLiability[] }> => getData('risk-management/user-product-liabilities'),
    select: (data) => data.items,
    enabled: !isEmpty(liabilitiesData?.items),
  });

  useEffect(() => {
    if (isSuccess) {
      const newValues: Record<ProductType, string> = { ...defaultValues };

      userProductLiabilities?.forEach((item) => {
        newValues[item.product] = item.liabilityId;
      });

      setValues(newValues);
    }
  }, [isSuccess, userProductLiabilities]);

  const { updateData: updateLiabilities } = useMutateData('risk-management/user-product-liabilities/', [
    QUERY_KEYS.userProductLiabilities,
  ]);

  const handleSave = (itemId: ProductType) => {
    updateLiabilities(itemId, {
      liabilityId: values[itemId],
    });
  };

  return (
    <Fragment>
      <TableContainer>
        <MuiTable aria-label='risk factors table' sx={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell align='center' width='50%'>
                <Typography variant='h5'>Liability assign</Typography>
              </TableCell>
              <TableCell align='center' />
              <TableCell align='center' />
            </TableRow>
          </TableHead>
          <TableBody>
            {PRODUCT_TYPES_OPTIONS.map((item) => {
              const id = item.id as ProductType;
              return (
                <TableRow key={item.id}>
                  <TableCell align='center' sx={styles.itemNameCell}>
                    {item.name}
                  </TableCell>
                  <TableCell align='center'>
                    <Select
                      variant='standard'
                      value={values[id]}
                      onChange={(e) => setValues({ ...values, [item.id]: e.target.value })}
                      disableUnderline
                      sx={styles.select}
                    >
                      {liabilitiesData?.items.map((liability) => {
                        return (
                          <MenuItem key={liability.id} value={liability.id}>
                            <Typography variant='body2'>{liability.name}</Typography>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                  <TableCell width={30}>
                    <IconButton disabled={!values[id]} onClick={() => handleSave(id)} sx={styles.iconBtn}>
                      <Tooltip title='Save'>
                        <Save color={values[id] ? 'primary' : 'disabled'} />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </Fragment>
  );
};

export default LiabilityAssignTable;
