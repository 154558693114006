import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthProvider } from 'neofusion-fe-shared';
import { RouterProvider } from 'react-router-dom';
import ReactQueryProvider from './ReactQueryProvider';
import router from './Routes';
import { KEYCLOAK_CONFIG, KEYCLOAK_INIT_OPTIONS } from './constants/keycloak';
import { AudioProvider } from './contexts/AudioContext';
import { ToastProvider } from './contexts/ToastContext';
import theme from './styles/theme';
import { GROWTHBOOK_API_HOST, GROWTHBOOK_CLIENT_KEY } from './constants/growthBook';
import GrowthbookProvider from './GrowthbookProvider';

const App = () => {
  return (
    <AuthProvider keycloakConfig={KEYCLOAK_CONFIG} keycloakInitOptions={KEYCLOAK_INIT_OPTIONS}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ReactQueryProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <GrowthbookProvider apiHost={GROWTHBOOK_API_HOST} clientKey={GROWTHBOOK_CLIENT_KEY}>
            <ThemeProvider theme={theme}>
              <ToastProvider>
                <AudioProvider src='/ticket-authorization-sound.mp3'>
                  <CssBaseline />
                  <RouterProvider router={router} />
                </AudioProvider>
              </ToastProvider>
            </ThemeProvider>
          </GrowthbookProvider>
        </ReactQueryProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
};

export default App;
