import promotionalTopContent from './sportsbook/promotionalTopContent';
import footerContent from './sportsbook/footerContent';
import promotionalSideContent from './sportsbook/promotionalSideContent';
import { PERMISSIONS } from '../../../constants/keycloak';
import promotionalTopContentCasino from './casino/promotionalTopContent';
import backgroundImageCasino from './casino/backgroundImage';
import logo from './sportsbook/logo';

export interface SanitySchemaType {
  name: string;
  title: string;
  type: string;
  fields: {
    name: string;
    title: string;
    type: string;
    description: string;
    of?: { type: string }[];
    inputComponent?: any;
    validation?: (Rule: any) => any;
  }[];
}

const sportsbookSchemaTypes = [promotionalTopContent, promotionalSideContent, footerContent];
const casinoSchemaTypes = [promotionalTopContentCasino, backgroundImageCasino];
const commonSchemaTypes = [logo];

export const getSchemaTypes = (roles: string[]): SanitySchemaType[] => {
  let schemaTypes: SanitySchemaType[] = [...commonSchemaTypes];
  if (roles?.includes(PERMISSIONS.sportsbookCMS)) {
    schemaTypes = [...schemaTypes, ...sportsbookSchemaTypes];
  }
  if (roles?.includes(PERMISSIONS.casinoCMS)) {
    schemaTypes = [...schemaTypes, ...casinoSchemaTypes];
  }
  return schemaTypes;
};
