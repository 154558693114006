import { Autocomplete, Chip, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import { QueryKey } from '@tanstack/react-query';
import { unionBy } from 'lodash-es';
import { SyntheticEvent } from 'react';
import { Control, Controller, FieldValues, Path, useController } from 'react-hook-form';
import useDebouncedSearch from '../../hooks/useDebouncedSearch';
import { ApiService } from '../../utils/api';

export type AutocompleteItem = {
  id: string;
  userId?: string;
  name?: string;
};

export type FormAutocompleteProps<O extends AutocompleteItem, TField extends FieldValues> = Omit<
  TextFieldProps,
  'error'
> & {
  control: Control<TField>;
  name: Path<TField>;
  url: string;
  apiService?: ApiService;
  queryKey: QueryKey;
  queryParams?: Record<string, unknown>;
  placeholder?: string;
  required?: boolean;
  label?: string;
  error?: {
    message?: string;
  };
  disabled?: boolean;
  hookEnabled?: boolean;
  multiple?: boolean;
  getOptionLabel: (options: O[], value: string) => string;
  optionsFilter?: 'userId' | 'id' | 'name';
};

const FormAutocomplete = <O extends AutocompleteItem, TField extends FieldValues>({
  control,
  name,
  url,
  apiService,
  queryKey,
  queryParams,
  placeholder,
  required,
  label,
  error,
  disabled,
  hookEnabled,
  multiple = false,
  getOptionLabel,
  optionsFilter = 'id',
  ...rest
}: FormAutocompleteProps<O, TField>) => {
  const { data, search, isFetching, onSearch } = useDebouncedSearch<O>(
    apiService,
    url,
    queryKey,
    {
      additionalParams: queryParams,
    },
    hookEnabled
  );

  const { field } = useController({ name, control });

  const options = unionBy(data?.selectedItems, data?.items, 'id');

  const autocompleteOptions = options?.reduce((acc: string[], item) => {
    const optionValue = item[optionsFilter];

    if (optionValue) {
      acc.push(optionValue);
    }

    return acc;
  }, []);

  const handleChange = (_: React.SyntheticEvent<Element, Event>, newValue: string[] | string | null) => {
    field.onChange(newValue);
    onSearch('');
  };

  const handleInputChange = (_event: SyntheticEvent<Element, Event>, value: string, reason: string) => {
    if (reason === 'input') {
      onSearch(value);
    }
  };

  const autocompleteValue = field.value ?? (multiple ? [] : null);

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <Autocomplete
            id='asynchronous-autocomplete'
            multiple={multiple}
            value={autocompleteValue}
            options={autocompleteOptions || []}
            onChange={handleChange}
            getOptionLabel={(id) => {
              return getOptionLabel(options || [], id);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                if (!autocompleteOptions.includes(option)) {
                  return null;
                }

                return <Chip label={getOptionLabel(options || [], option)} {...getTagProps({ index })} key={option} />;
              })
            }
            filterOptions={(x) => x}
            clearOnBlur={false}
            loading={isFetching}
            disabled={disabled}
            onInputChange={handleInputChange}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  value={search || ''}
                  label={label}
                  placeholder={placeholder}
                  error={!!error}
                  required={required}
                  helperText={error?.message}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isFetching ? <CircularProgress color='inherit' size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={rest.InputLabelProps}
                />
              );
            }}
          />
        );
      }}
    />
  );
};

export default FormAutocomplete;
