import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Competition, PaginatedData, Player, Sport, Tournament } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import useMutateData from '../../../hooks/useMutateData';

import { isEmpty } from 'lodash-es';
import { DialogType } from '../../../@types';
import { useModal } from '../../../contexts/ModalContext';
import { getNameOptionLabel } from '../../../helpers';
import { PlayerFormData, editPlayerFormSchema, playerFormSchema } from '../../../schema';
import { getData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormSelect from '../../molecules/FormSelect';
import ParticipantSelect from '../../molecules/ParticipantSelect';
import FormAutocomplete from '../FormAutocomplete';

const DEFAULT_FORM_DATA = {
  participantIds: [],
  firstName: '',
  lastName: '',
  displayName: '',
  isActive: false,
  sportId: '',
  competitionIds: [],
  tournamentIds: [],
};

type Props = DialogType;

const PlayerForm = ({ closeModal }: Props) => {
  const { item } = useModal<Player>();
  const { createData, updateData } = useMutateData('players', [QUERY_KEYS.players]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm<PlayerFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(item ? editPlayerFormSchema : playerFormSchema),
  });

  const sportId = watch('sportId') || item?.sport?.id;
  const competitionIds = watch('competitionIds');
  const tournamentIds = watch('tournamentIds');
  const participantIds = watch('participantIds');

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        sportId: item.sport.id,
        participantIds: item.participants.map((participant) => participant.id),
        competitionIds: item.competitions.map((competition) => competition.id),
        tournamentIds: item.tournaments.map((tournament) => tournament.id),
      });
    }
  }, [item, reset]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type !== 'change') return;

      if (name === 'sportId') {
        setValue('competitionIds', []);
        setValue('tournamentIds', []);
        setValue('participantIds', []);
      }

      if (name === 'competitionIds') {
        setValue('tournamentIds', []);
        setValue('participantIds', []);
      }

      if (name === 'tournamentIds') {
        setValue('participantIds', []);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: PlayerFormData) => {
    if (item) {
      updateData(item.id, data, () => {
        handleClose();
      });
    } else {
      createData(data, () => {
        handleClose();
      });
    }
  };

  const isEdit = !!item;

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={isEdit ? 'Edit player' : 'Add new player'}
      onClose={handleClose}
      isEdit={isEdit}
    >
      <TextField
        error={!!errors.firstName}
        helperText={errors.firstName?.message}
        label='First name'
        required
        {...register('firstName')}
        disabled={isEdit}
      />
      <TextField
        error={!!errors.lastName}
        helperText={errors.lastName?.message}
        label='Last name'
        required
        {...register('lastName')}
        disabled={isEdit}
      />
      <TextField
        error={!!errors.displayName}
        helperText={errors.displayName?.message}
        label='Display name'
        {...register('displayName')}
        disabled={isEdit}
      />
      <FormSelect
        label='Sport'
        name='sportId'
        required
        control={control}
        error={errors.sportId}
        options={sportsData?.items || []}
      />
      <FormAutocomplete<Competition, PlayerFormData>
        name='competitionIds'
        control={control}
        label='Competitions'
        required
        url='competitions'
        queryKey={[QUERY_KEYS.competitions, sportId, competitionIds]}
        queryParams={{
          sportId,
          competitionIds,
          isActive: true,
        }}
        disabled={!sportId}
        error={errors.competitionIds}
        hookEnabled={!!sportId}
        getOptionLabel={getNameOptionLabel}
        multiple
      />
      <FormAutocomplete<Tournament, PlayerFormData>
        name='tournamentIds'
        control={control}
        label='Tournaments'
        required
        url='tournaments'
        queryKey={[QUERY_KEYS.tournaments, competitionIds, tournamentIds]}
        queryParams={{
          competitionIds,
          tournamentIds,
        }}
        disabled={isEmpty(competitionIds)}
        error={errors.competitionIds}
        hookEnabled={!isEmpty(competitionIds)}
        getOptionLabel={getNameOptionLabel}
        multiple
      />
      <ParticipantSelect
        control={control}
        name='participantIds'
        queryParams={{ tournamentIds, participantIds }}
        error={errors.participantIds}
        multiple
        required
      />
      <FormFieldStack label='Status'>
        <Controller name='isActive' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
    </FormModalLayout>
  );
};

export default PlayerForm;
