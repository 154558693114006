import { Stack } from '@mui/material';
import { Control, useWatch } from 'react-hook-form';
import { BONUS_VALIDITY_PERIOD_OPTIONS } from '../../constants';
import { BonusFilterFormData } from '../../schema';
import DateTimeSelect from './DateTimeSelect';
import FormSelect from './FormSelect';
import { dayjs } from '../../config/dayjs';

type Props = {
  control: Control<BonusFilterFormData>;
  disabled?: boolean;
};

const ValidityPeriodSelect = ({ control, disabled }: Props) => {
  const [validityPeriod, fromTimestamp] = useWatch({
    control,
    name: ['validityPeriod', 'fromTimestamp'],
  });

  // const showDateRange = validityPeriod === 'CUSTOM';
  const showDateRange = false;

  return (
    <>
      <FormSelect
        control={control}
        name='validityPeriod'
        label='Validity period'
        options={BONUS_VALIDITY_PERIOD_OPTIONS}
        disabled={disabled}
        required
        allowDeselect={false}
      />
      {showDateRange && (
        <Stack direction='row' spacing={2}>
          <DateTimeSelect
            control={control}
            name='fromTimestamp'
            label='From'
            disablePast
            disabled={disabled}
            minDate={dayjs().add(1, 'day').startOf('day')}
          />
          <DateTimeSelect
            control={control}
            name='toTimestamp'
            label='To'
            disabled={disabled}
            minDate={dayjs(fromTimestamp).add(1, 'day')}
          />
        </Stack>
      )}
    </>
  );
};

export default ValidityPeriodSelect;
