import { zodResolver } from '@hookform/resolvers/zod';
import { TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DialogType, StyleObj } from '../../../@types';
import { Competition, PaginatedData, Sport } from '../../../@types/api';
import { QUERY_KEYS } from '../../../constants';
import { useModal } from '../../../contexts/ModalContext';
import useMutateData from '../../../hooks/useMutateData';
import { CompetitionFormData, competitionFormSchema } from '../../../schema';
import { getData } from '../../../utils/api';
import FormFieldStack from '../../atoms/FormFieldStack';
import Switch from '../../atoms/Switch';
import FormModalLayout from '../../layouts/FormModalLayout';
import FormNumberInput from '../../molecules/FormNumberInput';
import FormSelect from '../../molecules/FormSelect';

const DEFAULT_FORM_DATA = {
  sportId: '',
  name: '',
  shortName: null,
  position: null,
  isActive: false,
};

const styles: StyleObj = {
  position: {
    width: 80,
  },
};

type Props = DialogType;

const CompetitionForm = ({ closeModal }: Props) => {
  const { createData, updateData } = useMutateData('competitions', [QUERY_KEYS.competitions]);

  const { item } = useModal<Competition>();

  const { data: sportsData } = useQuery([QUERY_KEYS.sports], {
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        limit: 100,
        page: 1,
      }),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CompetitionFormData>({
    defaultValues: DEFAULT_FORM_DATA,
    resolver: zodResolver(competitionFormSchema),
  });

  useEffect(() => {
    if (item) {
      reset({
        ...item,
        sportId: item.sport.id,
      });
    }
  }, [item, reset]);

  const handleClose = () => {
    reset(DEFAULT_FORM_DATA);
    closeModal?.();
  };

  const onFormSubmit = (data: CompetitionFormData) => {
    if (item) {
      updateData(item.id, data, () => {
        handleClose();
      });
    } else {
      createData(data, () => {
        handleClose();
      });
    }
  };

  const isEdit = !!item;

  return (
    <FormModalLayout
      onSave={handleSubmit(onFormSubmit)}
      label={isEdit ? 'Edit competition' : 'Add new competition'}
      onClose={handleClose}
      isEdit={isEdit}
    >
      <FormSelect
        label='Sport'
        name='sportId'
        required
        control={control}
        error={errors.sportId}
        options={sportsData?.items || []}
      />
      <TextField
        error={!!errors.name}
        helperText={errors.name?.message}
        label='Name'
        {...register('name')}
        required
        disabled={isEdit}
      />
      <TextField
        error={!!errors.shortName}
        helperText={errors.shortName?.message}
        label='Short name'
        {...register('shortName')}
      />
      <FormFieldStack label='Position'>
        <FormNumberInput name='position' control={control} error={errors.position} sx={styles.position} />
      </FormFieldStack>
      <FormFieldStack label='Status'>
        <Controller name='isActive' control={control} render={({ field }) => <Switch {...field} ref={null} />} />
      </FormFieldStack>
    </FormModalLayout>
  );
};

export default CompetitionForm;
